import React, { useReducer, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Alert from '@paljs/ui/Alert';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Card, CardBody, CardHeader } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import styled from 'styled-components';
import ImageUploader from 'react-images-upload';
import { Button } from '@paljs/ui/Button';
import { Checkbox } from '@paljs/ui/Checkbox';
import CreateRoast from '../../components/RoastCreate';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const LIST = [
  {
    type: 'text',
    title: 'Name',
    item: 'name',
    placeholder: 'my coffee company',
    required: true,
  },
  {
    type: 'image',
    title: 'Logo',
    item: 'logo',
    required: true,
  },
  {
    type: 'image',
    title: 'Cover Image',
    notes: ['* Preferred dimensions are 1200px wide x 450px tall'],
    item: 'coverImage',
    required: true,
  },
  {
    type: 'images',
    title: 'Featured Images',
    item: 'photos',
  },
  {
    type: 'text',
    title: 'Headline',
    item: 'headline',
    placeholder: 'Premium Coffee Roaster for communities',
    required: true,
    maxLength: 40,
  },
  {
    type: 'text',
    title: 'Description',
    item: 'description',
    required: true,
  },
  {
    type: 'text',
    title: 'Address',
    item: 'address',
    required: true,
  },
  {
    type: 'text',
    title: 'City',
    item: 'city',
    required: true,
  },
  {
    type: 'text',
    title: 'Postal Code',
    item: 'postalCode',
    required: true,
  },
  {
    type: 'text',
    title: 'Region / State',
    item: 'region',
    required: true,
  },
  {
    type: 'float',
    title: 'latitude',
    item: 'latitude',
    required: true,
    notes: ['Visit https://www.latlong.net/ to find latitude & longitude by address'],
  },
  {
    type: 'float',
    title: 'longitude',
    item: 'longitude',
    required: true,
    notes: ['Visit https://www.latlong.net/ to find latitude & longitude by address'],
  },
  {
    type: 'text',
    title: 'Website',
    item: 'url',
  },
  {
    type: 'text',
    title: 'Facebook',
    item: 'facebook',
    placeholder: 'https://facebook.com/myplace',
  },
  {
    type: 'text',
    title: 'Instagram',
    item: 'instagram',
    placeholder: 'https://instagram.com/myprofile',
  },
  {
    type: 'text',
    title: 'Twitter',
    item: 'twitter',
    placeholder: 'https://twitter.com/myprofile',
  },
  {
    type: 'checkbox',
    title: 'Coffee Shop',
    item: 'isShop',
  },
  {
    type: 'checkbox',
    title: 'Coffee Roaster',
    item: 'isRoaster',
  },
];

const FETCH_PLACE = gql`
  query placeByKey($_key: ID!) {
    allTags {
      slug
      name
    }
    allOrigins {
      _key
      slug
      name
    }
    placeByKey(_key: $_key) {
      name
      url
      description
      coverImage
      logo
      address
      city
      region
      latlong
      photos
      headline
      postalCode
      tags {
        slug
      }
      isShop
      isRoaster
      social {
        name
        url
      }
      reviews {
        _key
        content
        reviewerName
        reviewerEmail
      }
      roasts {
        _key
        name
        description
        image
        roastLevel
        flavorProfile
        purchaseLinks
        tags
        origin {
          name
        }
      }
    }
  }
`;

const SAVE_PLACE_MUTATION = gql`
  mutation onUpdatePlace($_key: ID!, $placeInput: PlaceInput!) {
    onUpdatePlace(_key: $_key, place: $placeInput)
  }
`;

const IMAGE_UPLOAD_MUTATION = gql`
  mutation onFileUpload($file: String!) {
    onFileUpload(file: $file)
  }
`;

const DELETE_REVIEW_MUTATION = gql`
  mutation onDeletePlaceReview($key: ID!) {
    onDeletePlaceReview(_key: $key)
  }
`;

const DELETE_ROAST_MUTATION = gql`
  mutation onDeleteRoast($key: ID!) {
    onDeleteRoast(_key: $key)
  }
`;

const CREATE_REVIEW_MUTATION = gql`
  mutation onNewPlaceReview($placeReviewInput: PlaceReviewInput!) {
    onNewPlaceReview(review: $placeReviewInput)
  }
`;

export default function Edit() {
  const [fields, setFields] = useState<any>({});
  const [hasRun, setHasRun] = useState(false);
  const [_key, set_key] = useState(null);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [saveErrors, setSaveErrors] = useState('');
  const [alertText, setAlertText] = useState<string | null>(null);
  const [alertType, setAlertType] = useState('success');
  const [reviews, setReviews] = useState<any[]>([]);
  const [roasts, setRoasts] = useState<any[]>([]);
  const [reviewContent, setReviewContent] = useState('');
  const [reviewerEmail, setReviewerEmail] = useState('');
  const [reviewerName, setReviewerName] = useState('');
  const [reviewRating, setReviewRating] = useState(-1);
  const [tags, setTags] = useState<any[]>([]);
  const [reducerList, dispatch] = useReducer((state: any, action: any) => {
    state[action.item] = action.value;
    setFields(state);

    return state;
  }, {});

  const [uploadImage] = useMutation(IMAGE_UPLOAD_MUTATION);
  const [savePlace] = useMutation(SAVE_PLACE_MUTATION);
  const [saveReview] = useMutation(CREATE_REVIEW_MUTATION);
  const [deleteReview] = useMutation(DELETE_REVIEW_MUTATION);
  const [deleteRoast] = useMutation(DELETE_ROAST_MUTATION);

  if (typeof window !== 'undefined') {
    let params = new URLSearchParams(document.location.search.substring(1));

    if (!_key) {
      set_key(params.get('key') || '');
    }
  }

  const { data } = useQuery(FETCH_PLACE, {
    variables: {
      _key,
    },
  });

  if (data && !hasRun) {
    setHasRun(true);
    let pk = data.placeByKey;
    console.log(pk);

    if (!pk.social) {
      pk.social = [];
    }

    setTags(data.allTags);
    setReviews(pk.reviews);
    setRoasts(pk.roasts);

    dispatch({ item: 'latitude', value: pk.latlong[0] });
    dispatch({ item: 'longitude', value: pk.latlong[1] });
    pk.social.forEach((s) => {
      dispatch({ item: s.name, value: s.url });
    });

    for (let f in pk) {
      if (
        f !== '__typename' &&
        f !== 'social' &&
        f !== 'latlong' &&
        f !== '_key' &&
        f !== 'reviews' &&
        f !== 'tags' &&
        f !== 'roasts'
      ) {
        dispatch({ item: f, value: data.placeByKey[f] });
      }

      if (f === 'tags') {
        if (pk.tags) {
          dispatch({
            item: 'tags',
            value: data.placeByKey.tags.map((t) => {
              return t.slug;
            }),
          });
        } else {
          dispatch({ item: 'tags', value: [] });
        }
      }
    }

    forceUpdate();
  }

  const onSaveReview = async () => {
    const placeReviewInput = {
      content: reviewContent,
      reviewerName: reviewerName,
      reviewerEmail: reviewerEmail,
      place: _key,
    };

    if (reviewRating > -1) {
      placeReviewInput.rating = reviewRating;
    }

    const { data, error } = await saveReview({
      variables: {
        placeReviewInput,
      },
    });

    if (error) {
      setAlertText('Failed to save review. Check content and try again');
      setAlertType('Error');

      setTimeout(() => {
        setAlertText('');
      }, 3500);

      return;
    }

    if (data) {
      let rList = [];
      reviews.forEach((r) => {
        rList.push(r);
      });

      rList.push({
        reviewerEmail,
        reviewerName,
        content: reviewContent,
        place: _key,
        _key: data.onNewPlaceReview,
      });
      setReviews(rList);
      setReviewContent('');
      setReviewerName('');
      setReviewerEmail('');
      setReviewRating(-1);

      setAlertText('Saved new review');
      setAlertType('Success');

      setTimeout(() => {
        setAlertText('');
      }, 3500);
    }
  };

  const onDeleteReview = async (key: string) => {
    const list = [];

    reviews.forEach((r) => {
      if (r._key !== key) {
        list.push(r);
      }
    });

    setReviews(list);

    await deleteReview({
      variables: {
        key,
      },
    });
  };

  const onDeleteRoast = async (key: string) => {
    const list = [];
    console.log(key);

    roasts.forEach((r) => {
      if (r._key !== key) {
        list.push(r);
      }
    });

    setRoasts(list);

    const { data, error } = await deleteRoast({
      variables: {
        key,
      },
    });
    console.log(error);
  };

  const onSingleImageDrop = async (item: string, evt: any) => {
    const upload = new Promise<any>((resolve) => {
      var reader = new FileReader();

      reader.onload = async (e) => {
        const { data, errors } = await uploadImage({
          variables: {
            file: e.target.result,
          },
        });

        resolve({ item, value: data.onFileUpload });
      };

      reader.onerror = function (e) {
        // error occurred
        console.log('Error : ' + e.type);
      };

      reader.readAsDataURL(evt[0]);
    });

    const data = await upload;
    await dispatch(data);
    forceUpdate();
  };

  const onImageListDrop = async (item: string, evt: any) => {
    const upload = new Promise<any>((resolve) => {
      var reader = new FileReader();

      reader.onload = async (e) => {
        // binary data
        const { data, errors } = await uploadImage({
          variables: {
            file: e.target.result,
          },
        });

        const list = fields[item] || [];
        list.push(data.onFileUpload);
        resolve({ item, value: list });
      };

      reader.onerror = function (e) {
        // error occurred
        console.log('Error : ' + e.type);
      };

      reader.readAsDataURL(evt[0]);
    });

    const data = await upload;
    await dispatch(data);
    forceUpdate();
  };

  const removeImageFromList = async (item: string, key: number) => {
    const list = fields[item];
    list.splice(key, 1);
    await dispatch({ item, value: list });
    forceUpdate();
  };

  const updateTags = async (slug: string) => {
    const list = fields.tags;
    const slugIndex = list.findIndex((listSlug) => listSlug === slug);

    if (slugIndex > -1) {
      list.splice(slugIndex, 1);
    } else {
      list.push(slug);
    }
    dispatch({ item: 'tags', value: list });
    forceUpdate();
  };

  const handleSaveClick = async () => {
    try {
      const { data, errors } = await savePlace({
        variables: { _key, placeInput: fields },
      });

      if (data.onUpdatePlace) {
        setAlertText(`Successfully saved ${fields.name}`);
        setAlertType('Info');

        setTimeout(() => {
          setAlertText(null);
        }, 4000);
      } else {
        setAlertText(`failed to save. Check fields and try again`);
        setAlertType('Danger');

        setTimeout(() => {
          setAlertText(null);
        }, 4000);
      }
    } catch (err) {
      setAlertText(`failed to save. Check fields and try again.`);
      setAlertType('Danger');

      setTimeout(() => {
        setAlertText(null);
      }, 4000);
    }
  };

  return (
    <>
      {alertText && (
        <Alert
          style={{ boxShadow: '1px 1px 3px #ccc', position: 'fixed', top: '5.5rem', zIndex: 500 }}
          status={alertType}
        >
          {alertText}
        </Alert>
      )}
      <Row className="justify-content-center">
        <Col xs={12}>
          <h2 style={{ textTransform: 'uppercase', textAlign: 'center', padding: '1.5em', marginBottom: '1.5em' }}>
            Edit {fields.name}
          </h2>
        </Col>
        <Col xs={12} lg={9}>
          {LIST.map((i, key) => (
            <Card key={key}>
              <header>{i.title}:</header>
              <CardBody>
                {i.type === 'text' && (
                  <Input fullWidth size="Medium">
                    <input
                      value={fields[i.item] || ''}
                      type="text"
                      maxLength={i.maxLength || 500}
                      placeholder={i.placeholder}
                      onChange={(e) => {
                        dispatch({ item: i.item, value: e.target.value });
                        forceUpdate();
                      }}
                    />
                  </Input>
                )}

                {i.type === 'float' && (
                  <Input fullWidth size="Medium">
                    <input
                      type="number"
                      placeholder={i.placeholder}
                      value={fields[i.item] || 0}
                      onChange={(e) => {
                        dispatch({ item: i.item, value: parseFloat(e.target.value) });
                        forceUpdate();
                      }}
                    />
                  </Input>
                )}

                {i.type === 'image' && (
                  <div>
                    {fields[i.item] && fields[i.item] !== '' && (
                      <div>
                        <img src={fields[i.item]} style={{ maxWidth: '200px', maxHeight: '100px' }} />
                        <br />
                        <a
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            dispatch({ item: i.item, value: '' });
                            forceUpdate();
                          }}
                        >
                          remove
                        </a>
                      </div>
                    )}
                    <ImageUploader
                      withIcon={true}
                      buttonText="Choose images"
                      singleImage={true}
                      onChange={(evt) => {
                        onSingleImageDrop(i.item, evt);
                      }}
                      imgExtension={['.jpg', '.png', '.jpeg']}
                      maxFileSize={5242880}
                    />
                  </div>
                )}

                {i.type === 'checkbox' && (
                  <div>
                    <Checkbox
                      checked={fields[i.item] || false}
                      onChange={() => {
                        dispatch({ item: i.item, value: !fields[i.item] });
                        forceUpdate();
                      }}
                    />
                  </div>
                )}

                {i.type === 'images' && (
                  <div>
                    {fields[i.item] &&
                      fields[i.item].map((img: string, key: number) => (
                        <div key={key} style={{ display: 'inline-block' }}>
                          <img key={key} src={img} style={{ maxWidth: '200px', maxHeight: '100px' }} />
                          <br />
                          <a
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              removeImageFromList(i.item, key);
                            }}
                          >
                            remove
                          </a>
                        </div>
                      ))}

                    <ImageUploader
                      withIcon={true}
                      buttonText="Choose images"
                      singleImage={true}
                      onChange={(evt) => {
                        onImageListDrop(i.item, evt);
                      }}
                      imgExtension={['.jpg', '.gif', '.png', '.gif']}
                      maxFileSize={5242880}
                    />
                  </div>
                )}

                {i.required && (
                  <p>
                    <em>Required</em>
                  </p>
                )}
                {i.notes && (
                  <div>
                    {i.notes.map((n, k) => (
                      <p key={k}>{n}</p>
                    ))}
                  </div>
                )}
              </CardBody>
            </Card>
          ))}
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={9}>
          <Card>
            <CardHeader>Available Tags</CardHeader>
            <CardBody>
              {tags &&
                fields.tags &&
                tags.length > 0 &&
                tags.map((t, k) => (
                  <Checkbox
                    style={{ margin: '.5rem .75rem' }}
                    checked={!!fields.tags.find((slug) => t.slug === slug)}
                    onChange={() => {
                      updateTags(t.slug);
                    }}
                    key={k}
                  >
                    {t.name}
                  </Checkbox>
                ))}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col sm={6} md={4}>
          <Button onClick={handleSaveClick}>Save</Button>
          <p style={{ color: '#f00' }}>{saveErrors}</p>
        </Col>
      </Row>
      <Row style={{ marginTop: '4rem', textAlign: 'center' }}>
        <Col xs={12}>
          <h3>Reviews</h3>
        </Col>
      </Row>

      <Row style={{ maxWidth: '880px', margin: '0 auto' }}>
        {reviews.map((r, k) => (
          <Col key={k} xs={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col sm={6} md={4} lg={3}>
                    <p>{r.reviewerName}</p>
                  </Col>
                  <Col sm={6} md={4} lg={3}>
                    <p>{r.reviewerEmail}</p>
                  </Col>
                  <Col xs={12}>
                    <p>{r.content}</p>
                  </Col>
                </Row>
                <Row style={{ marginTop: '1.5rem' }}>
                  <Col xs={12}>
                    <a
                      style={{
                        border: '1px solid',
                        padding: '.5rem 1rem',
                        fontSize: '.85rem',
                        color: '#565656',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        onDeleteReview(r._key);
                      }}
                    >
                      delete
                    </a>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        ))}

        <Col style={{ marginTop: '2rem' }} xs={12}>
          <p style={{ textAlign: 'center' }}>
            <strong>Add New Review</strong>
          </p>
        </Col>
        <Col style={{ marginBottom: '7rem' }} xs={12}>
          <Card>
            <CardBody>
              <Row>
                <Col sm={6} md={4} lg={3}>
                  <p>
                    <strong>Reviewer Name:</strong>
                  </p>
                  <Input>
                    <input
                      type="text"
                      onChange={(e) => {
                        setReviewerName(e.target.value);
                      }}
                      value={reviewerName}
                    />
                  </Input>
                </Col>
                <Col sm={6} md={4} lg={3}>
                  <p>
                    <strong>Reviewer Email:</strong>
                  </p>
                  <Input>
                    <input
                      type="email"
                      onChange={(e) => {
                        setReviewerEmail(e.target.value);
                      }}
                      value={reviewerEmail}
                    />
                  </Input>
                </Col>
                <Col xs={12}>
                  <p>
                    <strong>Review Content:</strong>
                  </p>
                  <Input>
                    <textarea
                      value={reviewContent}
                      style={{ width: '100%', maxWidth: '100%' }}
                      type="text"
                      onChange={(e) => {
                        setReviewContent(e.target.value);
                      }}
                    />
                  </Input>
                </Col>
                <Col xs={12}>
                  <Button
                    disabled={reviewContent === '' || reviewerEmail === '' || reviewerName === ''}
                    onClick={onSaveReview}
                  >
                    + add review
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row style={{ marginTop: '4rem', textAlign: 'center' }}>
        <Col xs={12}>
          <h3>Roasts</h3>
        </Col>
      </Row>

      <Row style={{ maxWidth: '880px', margin: '0 auto' }}>
        {roasts.map((r, k) => (
          <Col key={k} xs={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col sm={12}>
                    <p>{r.name}</p>
                  </Col>
                  <Col md={6}>
                    <p>{r.origin.name}</p>
                  </Col>
                  <Col md={6}>
                    <p>{r.roastLevel}</p>
                  </Col>
                  <Col sm={12}>
                    <p>{r.description}</p>
                  </Col>
                  <Col xs={12}>
                    <img src={r.image} style={{ maxWidth: '150px', maxHeight: '150px' }} />
                  </Col>
                </Row>
                <Row style={{ marginTop: '1.5rem' }}>
                  <Col xs={12}>
                    <a
                      style={{
                        border: '1px solid',
                        padding: '.5rem 1rem',
                        fontSize: '.85rem',
                        color: '#565656',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        onDeleteRoast(r._key);
                      }}
                    >
                      delete
                    </a>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        ))}

        <Col style={{ marginTop: '2rem' }} xs={12}>
          <p style={{ textAlign: 'center' }}>
            <strong>Add New Roast</strong>
          </p>
        </Col>
        <Col style={{ marginBottom: '7rem' }} xs={12}>
          <CreateRoast
            onSave={(_key) => {
              setAlertText('Added new roast');
              setAlertType('Success');
              setTimeout(() => {
                setAlertText('');
              }, 3500);
            }}
            placeID={_key}
          />
        </Col>
      </Row>
    </>
  );
}
