import React, { useState } from 'react';
import { Card, CardBody, CardHeader } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import ImageUploader from 'react-images-upload';
import { Button } from '@paljs/ui/Button';
import { Checkbox } from '@paljs/ui/Checkbox';
import { gql, useMutation } from '@apollo/client';
import Select from '@paljs/ui/Select';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;
const IMAGE_UPLOAD_MUTATION = gql`
  mutation onFileUpload($file: String!) {
    onFileUpload(file: $file)
  }
`;

const CREATE_ROAST_MUTATION = gql`
  mutation onNewRoast($roastInput: RoastInput) {
    onNewRoast(roast: $roastInput)
  }
`;

export default function Comp({ placeID, onSave }) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [flavorProfile, setFlavorProfile] = useState([]);
  const [tags, setTags] = useState([]);
  const [roastLevel, setRoastLevel] = useState('');
  const [image, setImage] = useState('');
  const [purchaseLinks, setPurchaseLinks] = useState('');
  const [origin, setOrigin] = useState('');
  const [place, setPlace] = useState<string>(placeID || '');
  const [uploadImage] = useMutation(IMAGE_UPLOAD_MUTATION);
  const [createRoast] = useMutation(CREATE_ROAST_MUTATION);

  const origins = [
    {
      value: '43995344',
      label: 'Ethiopia',
    },
    {
      value: '43995395',
      label: 'Colombia',
    },
    {
      value: '43995417',
      label: 'Guatemala',
    },
    {
      value: '43995439',
      label: 'Jamaica',
    },
    {
      value: '43995466',
      label: 'Honduras',
    },
    {
      value: '43995498',
      label: 'Kenya',
    },
    {
      value: '43995515',
      label: 'Blend',
    },
  ];

  const onSaveRoast = async () => {
    const { data, errors } = await createRoast({
      variables: {
        roastInput: {
          origin,
          name,
          description,
          purchaseLinks,
          image,
          roastLevel,
          flavorProfile,
          place,
        },
      },
    });

    setPurchaseLinks('');
    setDescription('');
    setName('');
    setImage('');
    setRoastLevel('');
    setOrigin('');
    setFlavorProfile([]);

    if (onSave) {
      onSave(data.onNewRoast);
    }
  };

  const onSingleImageDrop = async (evt: any) => {
    const upload = new Promise<any>((resolve) => {
      var reader = new FileReader();

      reader.onload = async (e) => {
        const { data, errors } = await uploadImage({
          variables: {
            file: e.target.result,
          },
        });

        setImage(data.onFileUpload);
      };

      reader.onerror = function (e) {
        // error occurred
        console.log('Error : ' + e.type);
      };

      reader.readAsDataURL(evt[0]);
    });

    const data = await upload;
  };

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col sm={6} md={4} lg={3}>
              <p>
                <strong>Roast Name:</strong>
              </p>
              <Input>
                <input
                  type="text"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  value={name}
                />
              </Input>
            </Col>
            <Col xs={12}>
              <p>
                <strong>Description:</strong>
              </p>
              <Input>
                <textarea
                  value={description}
                  style={{ width: '100%', maxWidth: '100%' }}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </Input>
            </Col>
            <Col xs={12}>
              <p>
                <strong>Image:</strong>
              </p>
              {image && (
                <div>
                  <img src={image} style={{ maxWidth: '200px', maxHeight: '100px' }} />
                  <br />
                  <a
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setImage('');
                    }}
                  >
                    remove
                  </a>
                </div>
              )}
              <ImageUploader
                withIcon={true}
                buttonText="Choose images"
                singleImage={true}
                onChange={(evt) => {
                  onSingleImageDrop(evt);
                }}
                imgExtension={['.jpg', '.png', '.jpeg']}
                maxFileSize={5242880}
              />
            </Col>
            <Col lg={6}>
              <p>
                <strong>Roast Level:</strong>
              </p>
              <Select
                onChange={(e) => {
                  setRoastLevel(e.value);
                }}
                fullWidth
                placeholder="Select"
                options={[
                  { value: 'light', label: 'Light' },
                  { value: 'medium', label: 'medium' },
                  { value: 'dark', label: 'Dark' },
                ]}
              />
            </Col>
            <Col lg={6}>
              <p>
                <strong>Origin:</strong>
              </p>
              <Select
                onChange={(e) => {
                  setOrigin(e.value);
                }}
                fullWidth
                placeholder="Select"
                options={origins}
              />
            </Col>
            <Col style={{ marginTop: '2rem' }} xs={12}>
              <p>
                <strong>Purchase Link:</strong>
              </p>
              <Input style={{ width: '100%' }}>
                <input
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    setPurchaseLinks(e.target.value);
                  }}
                />
              </Input>
            </Col>
            <Col xs={12}>
              <Button disabled={name === ''} onClick={onSaveRoast}>
                + add roast
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
